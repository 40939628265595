<template>
	<div class="view view-scenarios">
		<scenarios />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scenarios from '@/components/master/scenarios'

export default {
	name: 'view-scenarios',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	components: { Scenarios }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

	

	
</style>
