<template>
	<div class="scenarios">
		<div class="side">
			<div class="scroll" v-scrollbar>
				<div>
					<div class="scroll-content">
						<div class="main-title"><ui-icon name="login-game-master" :size="32" />Library of scenarios</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main">
			<div class="form">
				<input class="input" placeholder="Search by scenario name">
			</div>
			<div class="scroll" v-scrollbar>
				<div>
					<div class="scroll-content">
						
						<scenario v-for="scenario in master.scenarios" :scenario="scenario" :key="scenario.id" />

					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scenario from '@/components/master/scenarios/scenario'

export default {
	name: 'scenarios',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'master' ])
	},
	components: { Scenario }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.scenarios
	flex flex-start flex-start
	height 100%
	width 100%
	blue_lighter = #7FBFD3
	.side
		height 100%
		width 350px
		padding 32px
		.main-title
			height 48px !important
			> .ui-icon
				transform translateY(-8px)
	.main
		height 100%
		width calc(100% - 350px)
		max-width 800px
		padding-right 16px
		.form
			flex center flex-end
			height 72px
			padding-bottom 8px
			.input
				width 100%
				background #E1E6E8 url(../../assets/img/search-blue-dark.svg) right 16px center no-repeat
				background-size 24px
				height 40px
				padding 0 24px
				border none
				border-radius 40px
				font inherit
				color dark
		.scroll
			height calc(100% - 72px)
			.scroll-content
				padding 16px
				
				>>> .scenario
					margin 0 0 24px 0
					&:last-child
						margin 0
		

	
</style>
